module.exports = [{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-mui-emotion/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-netlify-cms@7.12.0_gatsby@5.12.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_zndbfrqm7mvcpwdr4g2e5z4gii/node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.12.0_gatsby@5.12.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_qibmzbmoy56lprus3m244x4pya/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-NQ76EC660V"],"pluginConfig":{"head":false,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-facebook-pixel@1.0.8/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"461658289597305"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.12.0_gatsby@5.12.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2_ehqrgm2b3s2vcm6fowfwtogx4e/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6864833f23a1b7d02b9f6400bd5837f3"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.12.4_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.2.0_react@18.2.0__react@18.2.0__bm74nhtrnriqrwrxjnkae5yl4i/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
