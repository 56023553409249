exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-balanced-lifestyle-tsx": () => import("./../../../src/pages/balanced-lifestyle.tsx" /* webpackChunkName: "component---src-pages-balanced-lifestyle-tsx" */),
  "component---src-pages-bedankt-tsx": () => import("./../../../src/pages/bedankt.tsx" /* webpackChunkName: "component---src-pages-bedankt-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-markdown-remark-frontmatter-path-tsx": () => import("./../../../src/pages/blogs/{markdownRemark.frontmatter__path}.tsx" /* webpackChunkName: "component---src-pages-blogs-markdown-remark-frontmatter-path-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-groepstraining-tsx": () => import("./../../../src/pages/groepstraining.tsx" /* webpackChunkName: "component---src-pages-groepstraining-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inschrijven-tsx": () => import("./../../../src/pages/inschrijven.tsx" /* webpackChunkName: "component---src-pages-inschrijven-tsx" */),
  "component---src-pages-online-coaching-tsx": () => import("./../../../src/pages/online-coaching.tsx" /* webpackChunkName: "component---src-pages-online-coaching-tsx" */),
  "component---src-pages-over-sem-tsx": () => import("./../../../src/pages/over-sem.tsx" /* webpackChunkName: "component---src-pages-over-sem-tsx" */),
  "component---src-pages-pakketten-tsx": () => import("./../../../src/pages/pakketten.tsx" /* webpackChunkName: "component---src-pages-pakketten-tsx" */),
  "component---src-pages-personal-training-tsx": () => import("./../../../src/pages/personal-training.tsx" /* webpackChunkName: "component---src-pages-personal-training-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-specialisaties-tsx": () => import("./../../../src/pages/specialisaties.tsx" /* webpackChunkName: "component---src-pages-specialisaties-tsx" */),
  "component---src-pages-voeding-tsx": () => import("./../../../src/pages/voeding.tsx" /* webpackChunkName: "component---src-pages-voeding-tsx" */)
}

